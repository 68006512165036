/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .input {
        @apply p-2 border-2 text-black border-black outline-none rounded-md focus:border-primary-500 w-full max-w-lg;
    }

    app-section > section > div {
        @apply max-w-[1440px] ml-auto mr-auto;
    }
 }

 dialog::backdrop {
    backdrop-filter: blur(10px);
}